body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

:root {
    --bs-body-color: white;
    --bs-link-color: #d1c7ba;
    --bs-link-hover-color: #d59c53;
    --bs-secondary-color: #c1bbb2;
}

.btn-primary {
    --bs-btn-bg: #6f4922;
    --bs-btn-border-color: #6f4922;
    --bs-btn-hover-bg: #997613;
    --bs-btn-hover-border-color: #997613;
    --bs-btn-active-bg: #997613;
    --bs-btn-active-border-color: #997613;
}

.btn-link {
}

.card {
    --bs-card-bg: #271602
}

@keyframes bounce-animation {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-1rem);
    }
    60% {
        transform: translateY(-0.5rem);
    }
}

.bounce-animation {
    animation: bounce-animation 3s ease infinite;
}


@keyframes fade-in-animation {
    0% {
        opacity: 0;
    }
    66% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-in-animation {
    animation: fade-in-animation 3s linear;
}